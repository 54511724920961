import React, { useState, useEffect } from "react";
import HomepageSectionHeader from "../headers/HomepageSectionHeader";
import ShowcaseModal from "../modals/ShowcaseModal";
import PrimaryButton from "../buttons/PrimaryButton";
import moengageEvent from "../../helpers/MoengageEventTracking";
import { useSelector, useDispatch } from "react-redux";
import {
  bookTrialClickAttributes,
  buttonClickAttributes,
  cardClickAttributes,
} from "../../helpers/MoengageAttributeCreators";
import { leadsquaredActivityDispatcher } from "../../helpers/leadsquaredActivityDispatcher";
import {
  bookTrialClickActivity,
  buttonClickActivity,
  cardClickActivity,
} from "../../helpers/leadsquaredAttributeCreators";
import { setContext } from "../../store/actions/bookingActions";
//! import for SEO ********
import { VideoJsonLd } from "next-seo";
function HomepageShowcase() {
  const dispatch = useDispatch();
  // ! state for modal
  const [showModal, setShowModal] = useState(false);
  // ! function to open modal with a specific URL
  const [mediaUrl, setMediaUrl] = useState("");
  const [mediaType, setMediaType] = useState("");
  const [width, setWidth] = useState("");
  const authToken = useSelector((state) => state.auth.authToken);
  const userDetails = useSelector((state) => state.auth.userDetails);
  const isNextEduUser = useSelector(
    (state) => state.dashboard_session.isNextEduUser
  );
  useEffect(() => {
    if (mediaUrl !== "") {
      setShowModal(true);
    }
  }, [mediaUrl]);
  const widthSetter = (wid) => {
    if (window.innerWidth > 768) {
      return `${wid}%`;
    } else if (window.innerWidth <= 768 && window.innerWidth > 545) {
      return `${wid * 1.5}%`;
    } else if (window.innerWidth <= 545 && window.innerWidth > 360) {
      return `${wid * 2.15}%`;
    }
  };
  // ! function to close modal
  const modalClose = () => {
    moengageEvent(
      "Button_Click",
      buttonClickAttributes(
        3,
        "Dismiss Button",
        "/",
        10,
        1,
        3,
        "In The Showcase Section on the Homepage Displaying Videos"
      )
    );
    if (authToken.length > 0) {
      leadsquaredActivityDispatcher(
        buttonClickActivity(
          3,
          "Dismiss Button",
          "/",
          10,
          3,
          "In The Showcase Section on the Homepage Displaying Videos"
        ),
        authToken,
        userDetails.id
      );
    }
    setShowModal(false);
    setMediaUrl("");
    setWidth("");
  };
  const canShowTrial = () => {
    if (window) {
      let route = window.location.pathname;
      if (route.includes("/oktoberfest")) return false;
    }
    return true;
  };
  const homeShowCasevideos = [
    {
      title: "Pappad",
      one_liner: "Jhanavi(Age 7)",
      contentUrl: "homepage_showcase_video_1.mp4",
      thumbnailUrl: "homepage_showcase_video_1_thumbnail",
    },
    {
      title: "Off To Space",
      one_liner: "Tejas(Age 7)",
      contentUrl: "homepage_showcase_video_2.mp4",
      thumbnailUrl: "homepage_showcase_video_2_thumbnail",
    },
  ];

  return (
    <div className="homepage-showcase__wrapper">
      {homeShowCasevideos.map((video, index) => {
        <VideoJsonLd
          keyOverride={index}
          name={video.title}
          description={video.one_liner}
          contentUrl={`${process.env.NEXT_PUBLIC_ALL_HOMEPAGE_SHOWCASE_ASSETS_API}${video.contentUrl}`}
          uploadDate="2021-09-15T08:00:00+08:00"
          thumbnailUrls={[
            `${process.env.NEXT_PUBLIC_ALL_HOMEPAGE_SHOWCASE_ASSETS_API}${video.thumbnailUrl}`,
          ]}
        />;
      })}
      <HomepageSectionHeader
        headerContent="Our Studio Super Sparks:"
        linerContent=" Let your child join this community of talented young folks"
      />
      <div className="homepage-showcase__elements">
        <div
          className="homepage-showcase__elements--one showcase-single-element showcase-single-element--video"
          onClick={() => {
            moengageEvent(
              "Button_Click",
              buttonClickAttributes(
                1,
                "Play Icon",
                "/",
                10,
                1,
                3,
                "In The Showcase Section on the Homepage Displaying Videos"
              )
            );
            if (authToken.length > 0) {
              leadsquaredActivityDispatcher(
                buttonClickActivity(
                  1,
                  "Play Icon",
                  "/",
                  10,
                  3,
                  "In The Showcase Section on the Homepage Displaying Videos"
                ),
                authToken,
                userDetails.id
              );
            }
            moengageEvent(
              "Card_Click",
              cardClickAttributes(
                10,
                "video-1",
                "",
                "video-modal",
                "",
                "Showcase"
              )
            );
            if (authToken.length > 0) {
              leadsquaredActivityDispatcher(
                cardClickActivity(
                  10,
                  "video-1",
                  "null",
                  "video-modal",
                  0,
                  "Showcase"
                ),
                authToken,
                userDetails.id
              );
            }
            setMediaType("video");
            setMediaUrl(
              (mediaUrl) =>
                `${process.env.NEXT_PUBLIC_ALL_HOMEPAGE_SHOWCASE_ASSETS_API}homepage_showcase_video_1.mp4`
            );
          }}
        >
          <img
            src={`${process.env.NEXT_PUBLIC_ALL_HOMEPAGE_SHOWCASE_ASSETS_API}homepage_showcase_video_1_thumbnail`}
            alt="video"
          />
        </div>
        <div
          className="homepage-showcase__elements--two showcase-single-element"
          onClick={() => {
            moengageEvent(
              "Card_Click",
              cardClickAttributes(
                10,
                "image-1",
                "",
                "image-modal",
                "",
                "Showcase"
              )
            );
            if (authToken.length > 0) {
              leadsquaredActivityDispatcher(
                cardClickActivity(
                  10,
                  "image-1",
                  "null",
                  "image-modal",
                  0,
                  "Showcase"
                ),
                authToken,
                userDetails.id
              );
            }
            setMediaType("image");
            setMediaUrl(
              (mediaUrl) =>
                `${process.env.NEXT_PUBLIC_ALL_HOMEPAGE_SHOWCASE_ASSETS_API}homepage_showcase_image_2`
            );
            setWidth(widthSetter(30));
          }}
        >
          <img
            src={`${process.env.NEXT_PUBLIC_ALL_HOMEPAGE_SHOWCASE_ASSETS_API}homepage_showcase_image_2`}
            alt=""
          />
        </div>
        <div
          className="homepage-showcase__elements--three showcase-single-element"
          onClick={() => {
            moengageEvent(
              "Card_Click",
              cardClickAttributes(
                10,
                "image-2",
                "",
                "image-modal",
                "",
                "Showcase"
              )
            );
            if (authToken.length > 0) {
              leadsquaredActivityDispatcher(
                cardClickActivity(
                  10,
                  "image-2",
                  "null",
                  "image-modal",
                  0,
                  "Showcase"
                ),
                authToken,
                userDetails.id
              );
            }
            setMediaType("image");
            setMediaUrl(
              (mediaUrl) =>
                `${process.env.NEXT_PUBLIC_ALL_HOMEPAGE_SHOWCASE_ASSETS_API}homepage_showcase_image_1`
            );
            setWidth(widthSetter(30));
          }}
        >
          <img
            src={`${process.env.NEXT_PUBLIC_ALL_HOMEPAGE_SHOWCASE_ASSETS_API}homepage_showcase_image_1`}
            alt=""
          />
        </div>
        <div
          className="homepage-showcase__elements--four showcase-single-element showcase-single-element--video"
          onClick={() => {
            moengageEvent(
              "Card_Click",
              cardClickAttributes(
                10,
                "video-2",
                "",
                "video-modal",
                "",
                "Showcase"
              )
            );
            if (authToken.length > 0) {
              leadsquaredActivityDispatcher(
                cardClickActivity(
                  10,
                  "video-2",
                  "null",
                  "video-modal",
                  0,
                  "Showcase"
                ),
                authToken,
                userDetails.id
              );
            }
            moengageEvent(
              "Button_Click",
              buttonClickAttributes(
                1,
                "Play Icon",
                "/",
                10,
                1,
                3,
                "In The Showcase Section on the Homepage Displaying Videos"
              )
            );
            if (authToken.length > 0) {
              leadsquaredActivityDispatcher(
                buttonClickActivity(
                  1,
                  "Play Icon",
                  "/",
                  10,
                  3,
                  "In The Showcase Section on the Homepage Displaying Videos"
                ),
                authToken,
                userDetails.id
              );
            }
            setMediaType("video");
            setMediaUrl(
              (mediaUrl) =>
                `${process.env.NEXT_PUBLIC_ALL_HOMEPAGE_SHOWCASE_ASSETS_API}homepage_showcase_video_2.mp4`
            );
          }}
        >
          <img
            src={`${process.env.NEXT_PUBLIC_ALL_HOMEPAGE_SHOWCASE_ASSETS_API}homepage_showcase_video_2_thumbnail`}
            alt="video"
          />
        </div>
        <div
          className="homepage-showcase__elements--five showcase-single-element"
          onClick={() => {
            moengageEvent(
              "Card_Click",
              cardClickAttributes(
                10,
                "image-3",
                "",
                "image-modal",
                "",
                "Showcase"
              )
            );
            if (authToken.length > 0) {
              leadsquaredActivityDispatcher(
                cardClickActivity(
                  10,
                  "image-3",
                  "null",
                  "image-modal",
                  0,
                  "Showcase"
                ),
                authToken,
                userDetails.id
              );
            }
            setMediaType("image");
            setMediaUrl(
              (mediaUrl) =>
                `${process.env.NEXT_PUBLIC_ALL_HOMEPAGE_SHOWCASE_ASSETS_API}homepage_showcase_image_3`
            );
            setWidth(widthSetter(40));
          }}
        >
          <img
            src={`${process.env.NEXT_PUBLIC_ALL_HOMEPAGE_SHOWCASE_ASSETS_API}homepage_showcase_image_3`}
            alt=""
          />
        </div>
      </div>
      {canShowTrial() && !isNextEduUser && (
        <PrimaryButton
          buttonText="Book a FREE trial"
          version="version-2"
          linkTo="/book-a-trial"
          // shine={true}
          clickHandle={() => {
            dispatch(setContext("trial"));
            moengageEvent(
              "Button_Click",
              buttonClickAttributes(
                3,
                "Book a FREE trial",
                "/book-a-trial",
                1,
                4,
                3,
                "After Showcase Section"
              )
            );
            if (authToken.length > 0) {
              leadsquaredActivityDispatcher(
                buttonClickActivity(
                  3,
                  "Book a FREE trial",
                  "/book-a-trial",
                  1,
                  3,
                  "After Showcase Section"
                ),
                authToken,
                userDetails.id
              );
            }
            moengageEvent(
              "Book_Trial_Click",
              bookTrialClickAttributes(
                "Book a FREE trial",
                "/book-a-trial",
                1,
                "After Showcase Section",
                ""
              )
            );
            if (authToken.length > 0) {
              leadsquaredActivityDispatcher(
                bookTrialClickActivity(
                  "Book a FREE trial",
                  "/book-a-trial",
                  1,
                  "After Showcase Section",
                  window.location.pathname
                ),
                authToken,
                userDetails.id
              );
            }
          }}
        />
      )}

      {showModal ? (
        <ShowcaseModal
          modalCloseFunction={modalClose}
          media={mediaUrl}
          mediaType={mediaType}
          width={width}
        />
      ) : null}
    </div>
  );
}

export default HomepageShowcase;
