import React, { useRef, useEffect } from "react";
import gsap from "gsap";
import ReactPlayer from "react-player/lazy";
import { FBActivityDispatcher } from "../../helpers/fbConversionsHelpers/FBActivityDispatcher";
import { playVideoActivityFBPayload } from "../../helpers/fbConversionsHelpers/createFBActivityPayload";
import { useSelector } from "react-redux";
function ShowcaseModal({ media, modalCloseFunction, mediaType, width }) {
  const authToken = useSelector((state) => state.auth.authToken);
  const userDetails = useSelector((state) => state.auth.userDetails);
  const modalRef = useRef(null);
  const modalWrapperRef = useRef(null);
  const tweenRef = useRef(null);
  const opacityRef = useRef(null);
  useEffect(() => {
    modalRef.current.style.display = "flex";
    modalWrapperRef.current.style.display = "flex";
    tweenRef.current = gsap.fromTo(
      modalRef.current,
      { y: `-${window.innerHeight}`, autoAlpha: 1 },
      {
        y: `-${window.innerHeight * 0.1}`,
        autoAlpha: 1,
        duration: 0.6,
        ease: "back",
      }
    );
    opacityRef.current = gsap.fromTo(
      modalWrapperRef.current,
      { autoAlpha: 0 },
      { autoAlpha: 1, duration: 0.6 }
    );
  }, []);
  return (
    <div
      className="video-showcase-modal__wrapper  global-modal-wrapper"
      ref={modalWrapperRef}
    >
      <div
        className="video-showcase-modal"
        ref={modalRef}
        style={{ maxWidth: width || "70%" }}
      >
        {mediaType === "video" ? (
          <ReactPlayer
            url={media}
            controls
            controlsList="nodownload"
            disablePictureInPicture
            autoPlay
            width="100%"
            height="auto"
            onPlay={() => {
              // if (authToken.length > 0) {
              //   FBActivityDispatcher(
              //     playVideoActivityFBPayload(
              //       userDetails.id,
              //       `[ ${
              //         window?.location?.pathname || "Showcase modal"
              //       } | no-title ]`
              //     ),
              //     authToken,
              //     userDetails.id
              //   );
              // }
            }}
          />
        ) : null}

        {mediaType === "audio" ? (
          <ReactPlayer
            url={media}
            controls
            controlsList="nodownload"
            disablePictureInPicture
            autoPlay
            width="100%"
            height="auto"
            onPlay={() => {
              // if (authToken.length > 0) {
              //   FBActivityDispatcher(
              //     playVideoActivityFBPayload(
              //       userDetails.id,
              //       `[ ${
              //         window?.location?.pathname || "Showcase modal"
              //       } | no-title ]`
              //     ),
              //     authToken,
              //     userDetails.id
              //   );
              // }
            }}
          />
        ) : null}

        {mediaType === "image" ? <img src={media} alt="" /> : null}
        {/* <div className="video-showcase-modal__details">
          <h2 className="video-showcase-modal__details--title">
            Title of the video
          </h2>
          <h3 className="video-showcase-modal__details--name">
            Manas <span>(7 Yrs.)</span>
          </h3>
        </div> */}
        <div
          className="video-showcase-modal__close"
          onClick={() => {
            setTimeout(() => {
              modalCloseFunction();
            }, 600);
            opacityRef.current.reverse();
            tweenRef.current.reverse();
          }}
        >
          <img
            src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}cross.svg`}
            alt="close"
          />
        </div>
      </div>
    </div>
  );
}

export default ShowcaseModal;
