import React from "react";
import PrimaryButton from "../buttons/PrimaryButton";
import { useSelector, useDispatch } from "react-redux";
import { setScrollToCourseCategory } from "../../store/actions/rootActions";
import { useRouter } from "next/router";
import moengageEvent from "../../helpers/MoengageEventTracking";
import { cardClickAttributes } from "../../helpers/MoengageAttributeCreators";
import { leadsquaredActivityDispatcher } from "../../helpers/leadsquaredActivityDispatcher";
import { cardClickActivity } from "../../helpers/leadsquaredAttributeCreators";
function HomepageExpertCard({
  cardType,
  cardName,
  cardTitle,
  cardContent,
  cardCourse,
  cardImageAlt,
  hideButton,
}) {
  const dispatch = useDispatch();
  const router = useRouter();
  const authToken = useSelector((state) => state.auth.authToken);
  const userDetails = useSelector((state) => state.auth.userDetails);
  return (
    <div className={`expert-card expert-card--${cardType}`}>
      <img
        src={`${
          process.env.NEXT_PUBLIC_ALL_EXPERTS_IMAGES_API
        }${cardName.toLowerCase()}`}
        alt={cardImageAlt || ""}
      />
      <h4 className="expert-card__name">{cardName}</h4>
      <h4 className="expert-card__title">{cardCourse}</h4>
      <p className="expert-card__content">{cardContent}</p>
      {!hideButton ? (
        <PrimaryButton
          buttonText={`Explore ${cardTitle}`}
          version="version-3"
          linkTo="/courses"
          clickHandle={async () => {
            await dispatch(setScrollToCourseCategory(cardTitle));
            moengageEvent(
              "Card_Click",
              cardClickAttributes(11, cardName, "", `/courses/`, "", "Teacher")
            );
            if (authToken.length > 0) {
              leadsquaredActivityDispatcher(
                cardClickActivity(11, cardName, "-", `/courses/`, 0, "Teacher"),
                authToken,
                userDetails.id
              );
            }
          }}
        />
      ) : null}
    </div>
  );
}

export default HomepageExpertCard;
