import React, { useState, useEffect, useRef } from "react";
import HomepageSectionHeader from "../headers/HomepageSectionHeader";
import HomepageExpertCard from "../cards/HomepageExpertCard";

// ! Swiper
import SwiperCore, { Pagination, Navigation } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
SwiperCore.use([Pagination, Navigation]);

function HomepageExpertsSection({ hideButton }) {
  // const [centeredSlides, setCenteredSlides] = useState(false);

  const pagination = {
    clickable: true,
  };

  const hashNavigation = {
    watchState: true,
  };

  return (
    <>
      {/* <hr ref={startingRef} /> */}
      <HomepageSectionHeader
        headerContent="Learn with Experts and Artists"
        linerContent="The Quality Your Child Deserves"
      />
      <div className="homepage-experts-section__wrapper">
        <div className="homepage-experts-section">
          <Swiper
            slidesPerView={"auto"}
            hashNavigation={hashNavigation}
            navigation={true}
            spaceBetween={0}
            pagination={pagination}
            className="mySwiper"
          >
            <SwiperSlide>
              <HomepageExpertCard
                cardType="blue"
                cardName="Priyanka"
                cardCourse="Storytelling"
                cardTitle="Communication"
                cardContent="Priyanka has conducted 200+ storytelling sessions globally! She's bubbly, engaging and a student favourite."
                cardImageAlt="Priyanka is a speech expert, training children on Storytelling at sparkstudio.co-Online extracurricular courses for children"
                hideButton={hideButton}
              />
            </SwiperSlide>
            <SwiperSlide>
              <HomepageExpertCard
                cardType="yellow"
                cardName="Meera"
                cardCourse="Western Vocals"
                cardTitle="Music"
                cardContent="In 2020 Meera won the Indies for the Best Female Vocalist in India. She's a professional singer and songwriter."
                cardImageAlt="Meera is a music expert, teaching Western vocals at sparkstudio.co - Online extracurricular courses for children"
                hideButton={hideButton}
              />
            </SwiperSlide>
            {/* <SwiperSlide>
              <HomepageExpertCard
                cardType="yellow"
                cardName="Vivek"
                cardCourse="Guitar"
                cardTitle="Music"
                cardContent="Vivek has 15 years of experience playing guitar, teaching and composing music."
                cardImage={image1}
              />
            </SwiperSlide> */}
            <SwiperSlide>
              <HomepageExpertCard
                cardType="purple"
                cardName="Rashmi"
                cardCourse="Animation"
                cardTitle="Art"
                cardContent="Rashmi is a designer and an architect from the Royal College of Art, London."
                cardImageAlt="Rashmi is an expert in animation, teaching at sparkstudio.co - Online extracurricular courses for children"
                hideButton={hideButton}
              />
            </SwiperSlide>
            <SwiperSlide>
              <HomepageExpertCard
                cardType="blue"
                cardName="Rachita"
                cardCourse="Debate"
                cardTitle="Communication"
                cardContent="Rachita's a NIFT graduate, with 7 years of teaching experience, who guest lectures at NIFT."
                cardImageAlt="Rachita is a speech expert taking on debate courses at sparkstudio.co - Online extracurricular courses for children"
                hideButton={hideButton}
              />
            </SwiperSlide>
            <SwiperSlide>
              <HomepageExpertCard
                cardType="yellow"
                cardName="Purna"
                cardCourse="Western Vocals"
                cardTitle="Music"
                cardContent="A professional Rock and Country singer, 10+ years of experience performing and teaching music."
                cardImageAlt="Purna is a music expert, teaching Western vocals at sparkstudio.co - Online extracurricular courses for children"
                hideButton={hideButton}
              />
            </SwiperSlide>
            <SwiperSlide>
              <HomepageExpertCard
                cardType="blue"
                cardName="Shantheri"
                cardCourse="Storytelling"
                cardTitle="Communication"
                cardContent="12+ years of experience teaching, developing curriculum, and training teachers, 100+ storytelling classes "
                cardImageAlt="Shantheri is a speech expert, training children on Storytelling at sparkstudio.co-Online extracurricular courses for children"
                hideButton={hideButton}
              />
            </SwiperSlide>

            <SwiperSlide>
              <HomepageExpertCard
                cardType="purple"
                cardName="Richa"
                cardCourse="Art"
                cardTitle="Art"
                cardContent="Singapore-based watercolour artist, conducted 100+ workshops for children and adults"
                cardImageAlt="Richa is an art expert, teaching at sparkstudio.co - Online extracurricular courses for children"
                hideButton={hideButton}
              />
            </SwiperSlide>
            <SwiperSlide>
              <HomepageExpertCard
                cardType="blue"
                cardName="Tirthankar"
                cardCourse="Public Speaking"
                cardTitle="Communication"
                cardContent="Holds a Masters degree in Education, 6+ years of experience teaching and creating content for 1000s of schools"
                cardImageAlt="Tirthankar is an expert in teaching the art of Public speaking at sparkstudio.co-Online extracurricular courses for children"
                hideButton={hideButton}
              />
            </SwiperSlide>

            <SwiperSlide>
              <HomepageExpertCard
                cardType="blue"
                cardName="Savita"
                cardCourse="Public Speaking"
                cardTitle="Communication"
                cardContent="Holds a Masters degree in Social Work, 5+ years of experience developing English literacy skills of students in 100+ schools"
                cardImageAlt="Savita is a speech expert, training children on Storytelling at sparkstudio.co-Online extracurricular courses for children"
                hideButton={hideButton}
              />
            </SwiperSlide>
            <SwiperSlide>
              <HomepageExpertCard
                cardType="yellow"
                cardName="Raunak"
                cardCourse="Western Vocals"
                cardTitle="Music"
                cardContent="Raunak Mithra is a professional vocalist, pianist, and pedagogy expert from Christ University"
                cardImageAlt="A professional music composer and producer"
                hideButton={hideButton}
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default HomepageExpertsSection;
